<template>
    <div class="go-panel" :class="{ 'd-mode-first-up': mode.displayMode == 'firstUp', 'd-mode-all-up': mode.displayMode == 'allUp', 'd-mode-all-down': mode.displayMode == 'allDown' }">
        <div v-show="!showWord" class="center-cursor"></div>
        <div v-show="showWord">
            {{word}}
        </div>
    </div>
    
    <div class="ps-3 pe-3 navbar fixed-bottom navbar-expand-sm navbar-dark bg-dark bottom-panel">
        <button class="btn btn-dark" @click="check">
            <fa icon="far fa-eye" size="2xl" />
        </button>
        <button class="btn btn-dark" @click="newWordAndCheck">
            <fa icon="fa-solid fa-arrow-rotate-right" size="2xl" />
        </button>
        <router-link class="btn btn-dark" :to="'/mode/' + modeId">
            <fa icon="fa-solid fa-pen"  />
        </router-link>
        <router-link class="btn btn-dark" :to="'/'">
            <fa icon="fa-solid fa-list"  />
        </router-link>

        <!-- <div>
            <button @click="lessTime">-</button>
            {{hideAfterTime}}
            <button @click="moreTime">+</button>
        </div> -->
    </div>
</template>

<script>
//import ModeModel from '@/models/ModeModel.vue'
import modesDb from '@/services/ModesDb.vue'

export default {
    data() {
        return {
            modeId: '',
            mode: {},
            word: '',
            showWord: false,
            hideAfterTime: 100
        }
    },
    methods: {
        check() {
            this.showWord = true;
            setTimeout(() => {
                this.showWord = false;
            }, this.hideAfterTime);
        },
        newWordAndCheck() {
            this.newWord();
            this.check();
        },
        newWord() {
            this.word = this.mode.words[Math.floor(Math.random() * this.mode.words.length)];
        },
        lessTime() {
            this.hideAfterTime -= 25;
            if (this.hideAfterTime < 25) {
                this.hideAfterTime = 25;
            }
        },
        moreTime() {
            this.hideAfterTime += 25
        }
    },
    mounted() {
        let modeId = this.$route.params.id;
        modesDb.getItem(modeId).then((result) => {
            this.modeId = modeId;
            this.mode = result;
            this.hideAfterTime = this.mode.hideAfterTime;
            this.newWord();
        });
    }
}
</script>

<style>

    .go-panel {
        font-size: 3rem;
        text-align: center;
        margin-top: 30vh;
    }

    .bottom-panel {
        
    }

    .d-mode-first-up {
        text-transform:capitalize;
    }

    .d-mode-all-up {
        text-transform: uppercase;
    }

    .d-mode-all-down {
        text-transform: lowercase;
    }

    .center-cursor {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #00c4ff2e;
        display: inline-block;
    }

</style>