<template>
    <router-link class="mt-2 w-100 btn btn-light" :to="'/mode/0'">
    <fa icon="fa-solid fa-plus" />
    </router-link>
    <table class="table">
        <tbody>
            <tr v-for="(mode, modeId) in data" :key="modeId">
                <td>
                    <router-link class="btn btn-link" :to="'/go/' + modeId">{{mode.name}}</router-link>    
                </td>
                <td>
                    <router-link class="float-end btn btn-light btn-sm" :to="'/mode/' + modeId">
                        <fa icon="fa-solid fa-pen" />
                    </router-link>
                </td>
            </tr>
        </tbody>
    </table>
</template>
 
<script>
//import ModeModel from '@/models/ModeModel.vue'
import modesDb from '@/services/ModesDb.vue'

export default {
    data() {
        return {
            data: []
        }
    },
    mounted() {
        modesDb.getData().then((result) => {
            this.data = result;

            for (const item in this.data) {
                console.log(item);
                console.log(this.data[item]);
                
            }
        });
    }
}
</script>
