import { createRouter, createWebHistory } from 'vue-router'
import ModesView from '../views/ModesView.vue'
import ModeEditView from '../views/ModeEditView.vue'
import GoView from '../views/GoView.vue'

const routes = [
  {
    path: '/',
    redirect: '/modes'
  },
  {
    path: '/modes',
    component: ModesView
  },
  {
    path: '/mode/:id',
    component: ModeEditView
  },
  {
    path: '/go/:id',
    component: GoView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
