<script>
import axios from 'axios'
export default class {
    constructor(url, dbName) {
        this._showResultInConsole = true;
        this._fbUrl = url;
        this._dbName = dbName;
        this._fullUrl = `${this._fbUrl}/${this._dbName}`;
    }
    
    getData = () => {
        let showResultInConsole = this._showResultInConsole;
        let result = axios.get(`${this._fullUrl}/.json`).then((response) => {
            if (showResultInConsole) {
                console.log(response.data);
            }
            return response;
        })
        .catch(function (error) {
            if (showResultInConsole) {
                console.error(error);
            }
            return error;
        });
        return result;
    }

    getItem = (id) => {
        let showResultInConsole = this._showResultInConsole;
        let result = axios.get(`${this._fullUrl}/${id}/.json`).then((response) => {
            if (showResultInConsole) {
                console.log(response.data);
            }
            return response;
        })
        .catch(function (error) {
            if (showResultInConsole) {
                console.error(error);
            }
            return error;
        });
        return result;
    }

    addItem = (item) => {
        let showResultInConsole = this._showResultInConsole;
        let result = axios.post(`${this._fullUrl}/.json`, item).then((response) => {
            if (showResultInConsole) {
                console.log(response.data);
            }
            return response;
        })
        .catch(function (error) {
            if (showResultInConsole) {
                console.error(error);
            }
            return error;
        });
        return result;
    }
    
    updateItem = (id, item) => {
        let showResultInConsole = this._showResultInConsole;
        let result = axios.put(`${this._fullUrl}/${id}/.json`, item).then((response) => {
            if (showResultInConsole) {
                console.log(response.data);
            }
            return response;
        })
        .catch(function (error) {
            if (showResultInConsole) {
                console.error(error);
            }
            return error;
        });
        return result;
    }
    
    deleteItem = (id) => {
        let showResultInConsole = this._showResultInConsole;
        let result = axios.delete(`${this._fullUrl}/${id}/.json`).then((response) => {
            if (showResultInConsole) {
                console.log(response.data);
            }
            return response;
        })
        .catch(function (error) {
            if (showResultInConsole) {
                console.error(error);
            }
            return error;
        });
        return result;
    }
    
}
</script>