<template>
    <form v-on:submit.prevent="saveForm"> 

        <div class="mt-2 mb-4">
            <label for="nameInput" class="form-label">Name</label>
            <input type="text" v-model="formData.name" class="form-control" id="nameInput" placeholder="...">
        </div>

        <div class="mb-4">
            <label for="timeInput" class="form-label">Visibility time (ms)</label>
            <input type="number" v-model="formData.hideAfterTime" min="25" step="25" max="5000" class="form-control" id="timeInput" placeholder="min:25 - max:5000">
        </div>

        <div class="mb-4">
            <label for="wordsInput" class="form-label">Words (separator ',')</label>
            <textarea style="height:15rem" class="form-control" v-model="formData.wordsStr" id="wordsInput" placeholder="a, b, c"></textarea>
        </div>
        <div class="mb-4">
            <div class="form-check">
                <input class="form-check-input" value="none" type="radio" name="displayModeNone" id="displayModeNone" v-model="formData.displayMode">
                <label class="form-check-label" for="displayModeNone">
                    None
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" value="firstUp" type="radio" name="displayModeFirstUp" id="displayModeFirstUp" v-model="formData.displayMode">
                <label class="form-check-label" for="displayModeFirstUp">
                    First upper case
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" value="allUp" type="radio" name="displayModeAllUp" id="displayModeAllUp" v-model="formData.displayMode">
                <label class="form-check-label" for="displayModeAllUp">
                    Upper Case
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" value="allDown" type="radio" name="displayModeAllDown" id="displayModeAllDown" v-model="formData.displayMode">
                <label class="form-check-label" for="displayModeAllDown">
                    Lower case
                </label>
            </div>
        </div>

        <button class="float-end btn btn-dark">Submit</button>
    </form>
</template>

<script>
import ModeModel from '@/models/ModeModel.vue'
import modesDb from '@/services/ModesDb.vue'

export default {
    data() {
        return {
            modeId: null,
            formData: {}
        }
    },
    methods: {
        saveForm() {
            let words = this.formData.wordsStr.split(',').map(element => element.trim());
            let newMode = {
                name: this.formData.name, 
                words: words, 
                hideAfterTime: this.formData.hideAfterTime,
                displayMode: this.formData.displayMode
            };
            if (this.modeId && this.modeId != 0) {
                modesDb.updateItem(this.modeId, newMode).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                modesDb.addItem(newMode).then((result) => {
                    //console.log("/go/" + result.name)
                    this.$router.push("/go/" + result.name)
                });
            }
        }
    },
    mounted() {
        let modeId = this.$route.params.id;
        if (modeId && modeId != 0) {
            modesDb.getItem(modeId).then((data)=> {
                this.modeId = modeId;
                let mode = data;
                if (mode.words) {
                    mode.wordsStr = mode.words.sort().join(', ');
                }
                this.formData = mode;
            });
        }
        else {
            this.formData = new ModeModel(-1, '', [], 100, 'none');
        }
    }
}
</script>

<style>

</style>