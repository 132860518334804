<script>
import FirebaseDb from './FirebaseDb.vue'
export default new class {
    constructor() {
        this._db = new FirebaseDb('https://ps-reader-default-rtdb.europe-west1.firebasedatabase.app', 'read');
    }
    
    getData = () => {
        let promise = this._db.getData().then((result) => {
            return result.data;
        });

        return promise;
    }

    getItem = (id) => {
        let promise = this._db.getItem(id).then((result) => {
            return result.data;
        });

        return promise;
    }

    addItem = (item) => {
        let promise = this._db.addItem(item).then((result) => {
            return result.data;
        });

        return promise;
    }
    
    updateItem = (id, item) => {
        let promise = this._db.updateItem(id, item).then((result) => {
            return result.data;
        });

        return promise;
    }
    
    deleteItem = (id) => {
        let promise = this._db.deleteItem(id).then((result) => {
            return result.data;
        });

        return promise;
    }
    
}();
</script>