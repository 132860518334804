<template>
  <div class="container">
    <!-- <div class="row">
      <div class="col">
        <router-link class="btn btn-light" to="/modes">Modes</router-link>
      </div>
    </div> -->
    <div class="row">
      <div class="col">
        <router-view/>
      </div>
    </div>
  </div>
  
</template>

<style>
</style>
