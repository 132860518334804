<script>

    export default class {
        constructor(id, name, words, hideAfterTime, displayMode) {
            this.id = id;
            this.name = name;
            this.words = words;
            this.hideAfterTime = hideAfterTime;
            this.displayMode = displayMode;
        }   
    }

</script>